/** @jsx jsx */
import { jsx, Box, Flex, Card } from "theme-ui";
import React, { useEffect, useState } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import HFNlogo from "../../static/imgs/donations-logo.png";
import DonationLayout from "../layout/DonationLayout";

const donationSrcm = [
  {
    title: "Donation for SRCM - Corpus Fund",
    titleShort: "Corpus Fund",
    desc: "Donation to Corpus Fund would form a part of the capital of the Organization.",
    donationPath: "donation-corpus-fund",
    donationId: "18",
    modalTitle: "Donation for Corpus Fund",
  },
  {
    title: "Donation for SRCM - General Fund",
    titleShort: "General Fund",
    desc: "Donation to General Fund would be used for the objectives of the Organization and Heartfulness.",
    donationPath: "donation-general-fund",
    donationId: "19",
    modalTitle: "Heartfulness General Fund",
  },
];

const pageContent = (id, modalTitle) => {
  const titletext = modalTitle || "Heartfulness General Fund";
  return `<gatsby_donation amount='0' btntext='Donate Now' donationid=${id} layoutstyle='' btnstyle='cursor: pointer;'
    projectid='' btnbg='#444' colortext='#000000' colorprimary='#444444c7'
    colorsecondary='#444' colorbackground='white' colormuted='#f6f6f6'
    colorhighlight='#efeffe' titletext='${titletext}' allowoverseas='false'/>`;
};

const PageContent = React.memo(PageContentNonMemoized);

const renderDonateBtn = (id, titletext) => {
  const modalTitle = titletext || "Heartfulness General Fund";
  return <PageContent pageContent={pageContent(id, modalTitle)} />;
};

const ScrmInIframe = () => {
  const [isMobile, setIsMobile] = useState(false);

  const windowSizeTracker = () => {
    return window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    windowSizeTracker();
  }, []);

  return (
    <>
      <DonationLayout seoData={{ title: "Heartfulness Donations" }}>
        <section style={{ margin: "15px 0px" }}>
          <Box
            sx={{
              padding: isMobile ? "15px 20px" : "15px 70px",
              marginLeft: "auto",
              marginRight: "auto",
              letterSpacing: "0.3",
              color: "#777",
              fontSize: "18px",
            }}
          >
            <Flex
              sx={{
                flexDirection: ["column", null, null, "row", null],
                fontFamily: "opensans-light,sans-serif !important",
              }}
            >
              <Box sx={{ flex: ["2", null, null, "2", null] }}>
                <p>
                  Welcome! Thank you for choosing to donate to Shri Ram Chandra
                  Mission, India.
                </p>
                <p>
                  Shri Ram Chandra Mission Foundation supports all the events
                  and programs undertaken by Hearfulness.
                </p>
              </Box>
              <Box sx={{ flex: ["1", null, null, "1", null] }}>
                <img src={HFNlogo} alt="hfnlogo" width="300px" />
              </Box>
            </Flex>
            <p style={{ lineHeight: "34px" }}>
              On completion of the donation, the filled donation form will be
              generated and sent to you, which is to be printed, signed, scanned
              and sent to{" "}
              <b>
                <a
                  href="mailto:info.accounts@srcm.org"
                  style={{ color: "#4b77a1", textDecoration: "none" }}
                >
                  {" "}
                  info.accounts@srcm.org{" "}
                </a>
              </b>{" "}
              or can be couriered to the address mentioned in the form. Or you
              may fill and submit the form at your nearest center.
            </p>
            <div sx={{ border: "1px solid rgb(153, 187, 232)" }}>
              <p
                sx={{
                  backgroundColor: "rgb(228, 237, 254)",
                  borderBottom: "1px solid rgb(153, 187, 232)",
                  color: "rgb(21, 66, 139)",
                  fontWeight: "600",
                  padding: "10px 20px",
                }}
              >
                Please note
              </p>
              <p style={{ padding: "0px 20px", lineHeight: "34px" }}>
                At this time, due to government regulations, citizens of India
                only can make online donations. Others can contact{" "}
                <a
                  href="mailto:info.accounts@srcm.org"
                  style={{ color: "#4b77a1", textDecoration: "none" }}
                >
                  info.accounts@srcm.org
                </a>{" "}
                for making donations.
              </p>
            </div>
            <Card
              sx={{
                mt: "30px",
                bg: "#f5f5f5",
                border: "1px solid #e3e3e3",
                borderRadius: "4px",
                minHeight: "20px",
                padding: "19px",
              }}
            >
              <Box
                sx={{
                  bg: "#47c6e126",
                  border: "1px solid lightgray",
                  mt: 0,
                  margin: "0 20px 20px",
                  padding: "20px",
                }}
              >
                {donationSrcm &&
                  donationSrcm.map((data) => (
                    <div
                      key={data}
                      sx={{
                        borderBottom: "1px solid #c6c6c6",
                        pt: "10px",
                        pb: "10px",
                      }}
                    >
                      <b>{data.title}</b>
                      <p>{data.desc}</p>
                      {renderDonateBtn(data.donationId, data.modalTitle)}
                    </div>
                  ))}
              </Box>
              <p sx={{ marginLeft: "20px" }}>
                There are no refunds for donations.
              </p>
            </Card>
          </Box>
        </section>
      </DonationLayout>
    </>
  );
};

export default ScrmInIframe;
